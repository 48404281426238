const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */

    siteId: '5c51b7b6de75df22c1fe1a8e',
    authMode: ['providers'],
    authOAuthProviders: [
      [
        'WSSOKeycloak',
        [
          {
            realm: 'Quoi29',
            clientId: 'quoi29-gsuite',
            buttonText: 'Connexion Mediameeting (Google)',
          },
          {
            realm: 'Airzen',
            clientId: 'quoi29',
            buttonText: 'Connexion Airzen',
          },
          {
            realm: 'Mediameeting',
            clientId: 'Quoi29',
            buttonText: 'Connexion A2prl (Microsoft)',
          },
        ],
      ],
    ],

    disabledComponents: [
      // disabledComponents: [
      //  'wCardsLikeLabels',
      //  'wButtonLike',
      //  'wTagLikeCount',
      //  'wCardsCommentsLabels',
    ],
    metadatas: {
      name: 'Quoi de Neuf les AMI',
      supportEmailAddress: 'digital@mediameeting.fr',
    },

    analytics: {
      matomo: '2',
    },

    hasAutoplayContent: true,
    hasSuggestedContent: true,

    enableSplashScreen: 'wavy',
    useLTS: true,
    useShell: [
      'bb-default',
      {
        view: {
          allowHeadingOnMobile: false,
        },
        mode: 'universes',
        universes: [
          {
            type: 'tag',
            tagId: '61fd041564f6328624ab6d5d',
            i18n: {
              fr: {
                title: 'Editions mensuelles',
              },
            },
            heading: {
              color: '#e84b23',
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              canRadio: false,
            },
          },
          {
            type: 'tag',
            tagId: '5c5ac1701253510ffd0f6cc5',
            i18n: {
              fr: {
                title: 'Editions spéciales',
              },
            },
            heading: {
              color: '#e84b23',
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              canRadio: false,
            },
          },
          {
            type: 'tag',
            tagId: '5c5ac1831253519a860f6cc6',
            i18n: {
              fr: {
                title: 'Emissions',
              },
            },
            heading: {
              color: '#e84b23',
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              canRadio: false,
            },
          },
          {
            type: 'tag',
            tagId: '61faa2173fc74e44c0be72dc',
            i18n: {
              fr: {
                title: 'Emissions du CSE',
              },
            },
            heading: {
              color: '#e84b23',
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              canRadio: false,
            },
          },
        ],
      },
    ],
    nuxt: {
      head: {
        title: 'Quoi 29 les AMI, votre podcast mensuel',
        meta: [
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content:
              'width=device-width, initial-scale=1, minimal-ui, shrink-to-fit=no',
          },
          {
            hid: 'description',
            name: 'description',
            content: `Tous les mois, retrouvez l'essentiel des actus AMI en podcast !`,
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            hid: 'robots',
            name: 'robots',
            content: 'noindex,nofollow',
          },
          {
            hid: 'ogSiteName',
            name: 'og:site_name',
            content: 'Quoi 29 les AMI, votre podcast mensuel',
          },
          {
            hid: 'ogTitle',
            name: 'og:title',
            content: 'Quoi 29 les AMI, votre podcast mensuel',
          },
          {
            hid: 'ogDescription',
            name: 'og:description',
            content: `Tous les mois, retrouvez l'essentiel des actus AMI en podcast !`,
          },
          {
            hid: 'ogType',
            name: 'og:type',
            content: 'website',
          },
          {
            hid: 'ogImage',
            name: 'og:image',
            content: '/_nuxt/img/Splashscreenq29.674416e.png',
          },
        ],
      },
    },

    drawerNavigation: () => [
      {
        type: 'link',
        icon: 'mdi-cog-outline',
        value: '/settings',
        title: (ctx) => ctx.$t('glob_sidebar_settings'),
      },
      {
        icon: 'mdi-information-outline',
        value: 'info',
        title: (ctx) => ctx.$t('glob_sidebar_moreabout'),
        noAction: true,
        disableAutoClose: true,
        items: [
          {
            type: 'link',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_legals`),
            value: '/data/legal-notice',
          },
          {
            type: 'link',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_terms`),
            value: '/data/terms-of-service',
          },
          {
            type: 'link',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_data`),
            value: '/data/personal-data',
          },
          {
            type: 'method',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_versions`),
            value: 'versions',
            onClick: (context) => {
              context.dialogVersions = true
            },
          },
        ],
      },
      {
        type: 'link',
        icon: 'mdi-help-circle-outline',
        title: (ctx) => ctx.$t(`glob_sidebar_support`),
        value: '/support',
      },
      {
        condition(context) {
          return context.$spoke.site.$data('optAuthentication') === true
        },
        type: 'method',
        icon: 'mdi-exit-to-app',
        value: 'logout',
        title: (ctx) => ctx.$t('glob_sidebar_exit'),
        onClick: async (context) => {
          await context.$store.dispatch('auth/logout')
        },
      },
    ],

    WFeedBack: {
      firstFeedback: {
        title: 'Joue pour tenter de gagner la médaille d’or !',
        name: 'Jeux concours',
        type: 'form',
        headCaption: `Combien de médailles d’or va gagner la France pendant les jeux paralympiques ?`,
        subCaption: `Je suis informé que Le Quoi 2 9 traite les données recueillies pour
        permettre la diffusion de votre dédicace. Pour en savoir plus,
        reportez-vous à notre <a href="/data/personal-data">Politique de données personnelles.</a>`,
        formSentMessage: `Merci pour ta réponse !`,
        pseudonym: {
          label: 'Nom & prénom ou pseudo',
          placeholder: 'Martin Dupont',
          required: true,
        },
        recorderForm: [
          {
            label: 'Entité',
            items: ['Mediameeting', 'A2PRL', 'AirZen Radio'],
            value: '',
            type: 'select',
            required: false,
          },
          {
            label: 'Adresse mail',
            placeholder: 'martindupont@hotmail.fr',
            value: '',
            type: 'email',
            required: true,
          },
          {
            label: 'Réponse',
            placeholder: '2',
            value: '',
            type: 'text-field',
            required: true,
          },
          {
            label: 'J’accepte l’utilisation de mes données personnelles',
            value: false,
            type: 'checkbox',
            required: true,
          },
          {
            label: "J'accepte que mon message soit diffusé dans le Quoi 29",
            value: false,
            type: 'checkbox',
            required: true,
          },
        ],
      },
      secondFeedback: {
        title: 'Enregistre ton audio',
        name: 'enregistrement de la dédicace',
        type: 'audio',
        maxDuration: 15,
        headCaption: `Prend ta plus belle voix et laisse ton message audio`,
        subCaption: `je suis conscient que je vais me faire afficher, mais je n'agis pas sous la menace. Pour en savoir plus, reportez-vous à notre <a href="/data/personal-data">Politique de données personnelles.</a>`,
        formSentMessage: `On a bien enregistré ton audio, tu passeras à l'antenne`,
        pseudonym: {
          label: 'Nom & prénom',
          placeholder: 'Martin Dupont',
          required: true,
        },
        recorderForm: [
          {
            label: 'Entreprise',
            items: ['Mediameeting', 'A2PRL', 'AirZen Radio'],
            value: '',
            type: 'select',
            required: false,
          },
          {
            label: 'Numéro de téléphone',
            placeholder: '01 23 45 67 89',
            value: '',
            type: 'phone-number',
            required: true,
          },
          {
            label: "J'accepte que mon message soit diffusé dans le Quoi 29",
            value: false,
            type: 'checkbox',
            required: true,
          },
        ],
      },
    },
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
