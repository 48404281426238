<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="$route.name !== 'search'">
      <v-card-text
        class="w-block__body2"
        :class="{
          'pl-3 pr-3 pt-2': $voicer.isMobile,
          'pb-2': $voicer.isMobile && !$slots['cta'],
          'pa-4': $voicer.isDesktopOrTablet,
          'body-2': $voicer.isMobile,
        }"
      >
        Quoi 29 les AMI, c’est votre podcast ! N’hésitez pas à nous faire vos
        suggestions sur
        <a href="mailto:quoi29@mediameeting.fr">quoi29@mediameeting.fr</a>
        <div>
          <v-btn
            class="white--text"
            color="#E84B23"
            @click.stop="showSecondFeedbackModal = true"
          >
            Laisse une dédicace
          </v-btn>
          <w-feedback-popup
            id="secondFeedback"
            v-model="showSecondFeedbackModal"
          />
        </div>
      </v-card-text>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WFeedbackPopup from '../components/WFeedbackPopup'

export default {
  components: {
    WFeedbackPopup,
  },
  data() {
    return {
      showSecondFeedbackModal: false,
    }
  },
  computed: {
    ...mapGetters({
      userName: 'auth/name',
    }),
  },
  methods: {
    handleOpen(url) {
      window.open(url)
    },
  },
}
</script>

<style lang="scss" scoped>
.w-block__pre-heading {
  display: flex;
  justify-content: center;
  text-align: center;
}
.v-sheet {
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .message_welcome_sir {
    flex-direction: column;
    align-items: center;
    div {
      margin-top: 15px;
    }
  }
}
</style>
